const options = {
  maxSizeMB: 10,
  maxWidthOrHeight: 1920,
  useWebWorker: false,
};

const imageCompress = async (image: File): Promise<File> => {
  let result = image;

  if (['image/png', 'image/jpeg'].includes(image.type)) {
    try {
      const { default: imageCompression } = await import(
        'browser-image-compression'
      );
      // @ts-ignore
      result = await imageCompression(image, options);
      if (result.size > image.size) {
        result = image;
      }
    } catch (error) {
      result = image;
    }
  }

  return result;
};

export default imageCompress;
