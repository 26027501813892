/* eslint-disable no-var */
var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Show more",
  "client.header.login.button": "Log in",
  "client.header.logout.button": "Log out",
  "client.user-id-modal.input-placeholder": "User ID",
  "client.user-id-modal.input-incorrect-id": "Incorrect user ID.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Try copying and pasting your ID from the game.",
  "client.user-id-modal.continue-button": "Continue",
  "client.user-id-modal.go-back": "Go back",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Show more",
  "client.privacy-settings.open-button": "Privacy settings",
  "client.usa-privacy-settings.open-button": "Do not sell my data",
  "client.user-id-modal.continue-with": "Continue with {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Enter your user ID",
  "document.pages.auth.subtitle": "or enter ID",
  "document.pages.auth.placeholder": "Example: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Can't find your user ID?",
  "document.pages.auth.instruction.steps.description": "Describe how the user can find their personal ID. You can add several steps and images.",
  "document.pages.seo.title": "The New Game is out now!",
  "document.pages.seo.description": "Your Game takes players on a grand adventure filled with detractors, monsters, gold hunters and grave danger.",
  "document.pages.seo.ogTitle": "The New Game is out now!",
  "document.pages.seo.ogDescription": "Your Game takes players on a grand adventure filled with detractors, monsters, gold hunters and grave danger.",
  "default-data.templates.google-play.contact-us": "Contact us {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "TOP UP THE IN-GAME WALLET",
  "blocks.topup.values.description": "Enter user ID",
  "blocks.topup.values.userIdPlaceholder": "Example: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "CAN'T FIND YOUR USER ID?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Step name",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Describe how the user can find their personal ID. You can add several steps and images.",
  "default-data.templates.topup.app-support": "App support",
  "default-data.templates.topup.age-raiting": "Age rating",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>YOUR GAME TITLE</h1>",
  "blocks.retailers.values.title": "<h2>Choose your edition</h2>",
  "blocks.hero.values.description": "Your Game takes players on a grand adventure filled with detractors, monsters, gold hunters and grave danger.",
  "blocks.hero.values.affiliate": "Revenue from this purchase will be shared with your affiliate",
  "blocks.hero.components.platforms.label": "Available for",
  "blocks.hero.components.subscribe.label": "Enter your email",
  "blocks.hero.components.subscribe.values.text" : "Subscribe to our newsletter to get the latest news and updates",
  "blocks.hero.components.scroll.label": "Scroll",
  "blocks.footer.values.name": "Enter your text",
  "blocks.footer.values.affiliate": "Join affiliate program",
  "blocks.hero.components.buy.label": "Buy now",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Unavailable",
  "blocks.hero.components.customButton.link.label": "Set up button",
  "blocks.header.components.customButton.link.label": "Set up button",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Unavailable",
  "blocks.packs.components.pack.customButton.link.label": "Set up button",
  "blocks.packs.components.pack.standart.components.buy.label": "Buy now",
  "blocks.packs.components.pack.premium.components.buy.label": "Buy now",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Buy now",
  "blocks.packs.components.pack.platinum.components.buy.label": "Buy now",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Unavailable",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Unavailable",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Unavailable",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Unavailable",
  "blocks.packs.components.pack.standart.value.ribbon": "Most popular",
  "blocks.packs.components.pack.premium.value.ribbon": "Most popular",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Most popular",
  "blocks.packs.components.pack.platinum.value.ribbon": "Most popular",
  "blocks.description.values.title": "<h2>GAME DESCRIPTION</h2>",
  "blocks.description.components.text.label": "Your Game takes players on a grand adventure filled with detractors, monsters, gold hunters and grave danger. Dramatic events unfolded which overturned your life and now you have to fight for survival in a new hostile world. Discover, explore, and conquer as you survive through different dimensions of reality!",
  "blocks.faq.values.title": "<h2>FAQ</h2>",
  "blocks.faq.components.question.question": "How can I play Your Game?",
  "blocks.faq.components.question.answer": "To play Your Game, you need to buy it and specify your email. You will receive the activation key, submit it and enjoy the game.",
  "blocks.news.values.title": "<h2>News</h2>",
  "blocks.news.values.description": "Check out our recent articles to see what's new.",
  "blocks.store.values.title": "<h2>Store</h2>",
  "blocks.store.buy_button.text": "BUY NOW FOR {amount}",
  "blocks.embed.values.title": "<h2>Social media widgets</h2>",
  "blocks.embed.components.twitter.title": "<h3>TWITTER FEED</h3>",
  "blocks.embed.components.facebook.title": "<h3>FACEBOOK FEED</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Select your edition",
  "blocks.packs.values.description": "Each edition includes exclusive perks and benefits that you will get after purchase.",
  "blocks.packs.components.pack.standart.value.subtext": "Available now",
  "blocks.packs.components.pack.premium.value.subtext": "Available now",
  "blocks.packs.components.pack.ultimate.value.subtext": "Available now",
  "blocks.packs.components.pack.platinum.value.subtext": "Available now",
  "blocks.packs.components.pack.standart.value.title": "<h3>Standard Edition</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Premium Edition</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Platinum Edition</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Ultimate Edition</h3>",
  "blocks.packs.components.pack.standart.value.description": "Game key, exclusive badge, soundtrack, map and additional armor pack",
  "blocks.packs.components.pack.premium.value.description": "Game key, exclusive badge, soundtrack, map, additional armor pack and additional weapon pack",
  "blocks.packs.components.pack.platinum.value.description": "Game key, exclusive badge, soundtrack, map, digital art book, additional armor pack, additional weapon pack, one random skin pack",
  "blocks.packs.components.pack.ultimate.value.description": "Game key, exclusive badge, soundtrack, map, digital art book, digital song book, additional armor pack, two additional weapon packs and three random skin packs",
  "blocks.subscriptions.values.card.title": "Go premium and get benefits",
  "blocks.subscriptions.values.title": "Activate subscription",
  "blocks.subscriptions.values.heading": "Subscriptions",
  "blocks.subscriptions.values.bullets.one.title": "Digital content",
  "blocks.subscriptions.values.bullets.one.description": "Images, wallpaper, behind-the-scenes content, in-game soundtracks",
  "blocks.subscriptions.values.bullets.two.title": "In-game perks",
  "blocks.subscriptions.values.bullets.two.description": "Character skins, visual effects, storyline cards",
  "blocks.subscriptions.values.bullets.three.title": "3 In-game lootboxes",
  "blocks.subscriptions.values.bullets.three.description": "5 extra passes to the Hall of Chaos, 10 character rebirths",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Next renewal",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Renew now",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Manage plan",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Select your plan:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Configure subscription",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} day(s) for free",
  "editor.subscriptions-packs.button.then.price.per.day": "Then {amount} per day",
  "editor.subscriptions-packs.button.then.price.per.week": "Then {amount} per week",
  "editor.subscriptions-packs.button.then.price.per.month": "Then {amount} per month",
  "editor.subscriptions-packs.button.then.price.per.year": "Then {amount} per year",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Then {amount} per {periodValue} days",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Then {amount} per {periodValue} weeks",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Then {amount} per {periodValue} months",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Then {amount} per {periodValue} years",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Then {amount} per lifetime",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "as first payment, then {amount} per day",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "as first payment, then {amount} per week",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "as first payment, then {amount} per month",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "as first payment, then {amount} per year",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "as first payment, then {amount} per lifetime",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "as first payment, then {amount} per {periodValue} days",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "as first payment, then {amount} per {periodValue} weeks",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "as first payment, then {amount} per {periodValue} months",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "as first payment, then {amount} per {periodValue} years",
  "editor.subscriptions-packs.button.price.per.day": "{amount} per day",
  "editor.subscriptions-packs.button.price.per.week": "{amount} per week",
  "editor.subscriptions-packs.button.price.per.month": "{amount} per month",
  "editor.subscriptions-packs.button.price.per.year": "{amount} per year",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} per {periodValue} days",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} per {periodValue} weeks",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} per {periodValue} months",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} per {periodValue} years",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per day for {periodsCounts} period(s), then {amount} per day",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per week for {periodsCounts} period(s), then {amount} per week",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per month for {periodsCounts} period(s), then {amount} per month",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per year for {periodsCounts} period(s), then {amount} per year",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} days for {periodsCounts} period(s), then {amount} per {periodValue} days",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} weeks for {periodsCounts} period(s), then {amount} per {periodValue} weeks",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} months for {periodsCounts} period(s), then {amount} per {periodValue} months",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} years for {periodsCounts} period(s), then {amount} per {periodValue} years",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Then {amount} as first payment, then {amountPromo} per day",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Then {amount} as first payment, then {amountPromo} per week",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Then {amount} as first payment, then {amountPromo} per month",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Then {amount} as first payment, then {amountPromo} per year",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Then {amount} as first payment, then {amountPromo} per {periodValue} days",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Then {amount} as first payment, then {amountPromo} per {periodValue} weeks",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Then {amount} as first payment, then {amountPromo} per {periodValue} months",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Then {amount} as first payment, then {amountPromo} per {periodValue} years",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Then {amount} as first payment, then {amountPromo} per lifetime",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "for {periodsCounts} period(s), then {amount} per day",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "for {periodsCounts} period(s), then {amount} per week",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "for {periodsCounts} period(s), then {amount} per month",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "for {periodsCounts} period(s), then {amount} per year",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "for {periodsCounts} period(s), then {amount} per {periodValue} days",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "for {periodsCounts} period(s), then {amount} per {periodValue} weeks",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "for {periodsCounts} period(s), then {amount} per {periodValue} months",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "for {periodsCounts} period(s), then {amount} per {periodValue} years",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo}, the season ends on {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo}, the season starts on {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Then {amount}, the season ends on {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Then {amount}, the season starts on {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per day",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per week",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per month",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per year",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} days",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} weeks",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} months",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} years",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo} per lifetime",
  "editor.subscriptions-packs.button.then.price.promo.price": "Then <span class='subscriptions-container__price'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per day",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per week",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per month",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per year",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} days",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} weeks",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} months",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per {periodValue} years",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class='subscriptions-container__price'>{amount}</span> {amountPromo} per lifetime",
  "editor.subscriptions-packs.button.then.price": "Then {amount}",
  "editor.subscriptions-packs.button.season.ends": "The season ends on {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "The season starts on {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / day",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / week",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / month",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / year",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} days",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} weeks",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} months",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} years",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / lifetime",
  "blocks.requirements.values.title": "<h2>System requirements</h2>",
  "blocks.requirements.components.platform_req.value.title": "Platform",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Graphics",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "OS",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Storage",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Processor",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Sound card",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Memory",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minimum",
  "default-data.values.requirements.title.recommended": "Recommended",
  "default-data.values.requirements.item.directx.minimum": "Version 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "DirectX9 Compatible",
  "default-data.values.requirements.item.sound_card.recommended": "DirectX9 Compatible",
  "default-data.values.requirements.item.directx.recommended": "Version 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Hello, friend!",
  "default-data.customization.receipt.description": "Thank you for your purchase! We appreciate you playing our game.",
  "default-data.customization.receipt.footer": "© All rights reserved.",
  "blocks.store.components.storeSection.title": "Items",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "You have successfully subscribed!",
  "notification.hero.components.subscribe.subscription.success": "We have just sent you a confirmation email. Please follow the link in the email to activate your subscription.",
  "notification.hero.components.subscribe.error": "Something went wrong. Please try again later or contact our integration team at <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "You are already subscribed.",
  /* notifications values END */
  "period.years_short": "yrs.",
  "period.months_short": "mos.",
  "period.weeks_short": "wks.",
  "period.days_short": "d",
  "subscription.button.text": "Subscribe for</br>{fullCurrency} per {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Your subscription",
  "client.subscription.button.configuration": "Manage subscription",
  "client.subscription.button.lifetime_text": "Subscribe for life</br>for {fullCurrency}",
  "client.thank_you_modal.title": "Thanks for subscribing!",
  "client.thank_you_modal.description": "Your subscription renews automatically. The payment will be charged regularly according to your subscription terms. You can cancel it anytime.",
  "client.thank_you_modal.input.description": "If you want to see yourself in the list of our subscribers, enter your name below.",
  "client.thank_you_modal.button.default.text": "Thanks, dismiss ",
  "client.thank_you_modal.input.placeholder": "Your name",
  "client.thank_you_modal.button.show_list": "Show all subscribers",
  "client.subscribers_list_modal.title": "Full list of subscribers",
  "client.subscribers_list_modal.description": "Many thanks to all users who joined us and subscribed for the game!",
  "client.subscribers_list_modal.show_more_button": "Show more",
  "client.subscribers_list_modal.anonymous": "and anonymous users",
  "client.footer.button.list_of_subscribers": "List of subscribers",
  "client.footer.xsollalogo.powered": "Powered by", // копирайт в футере
  "client.footer.xsollalogo.sb": "Xsolla Site Builder",
  "client.empty_subscribers_modal.title": "Subscribers will be listed here",
  "client.empty_subscribers_modal.description": "Be the first to subscribe for our game.",
  "client.empty_subscribers_modal.button": "Select subscription now",
  "client.news.blurred.description": "Become a {subscription_name} subscriber to unlock this content.",
  "client.news.blurred.button.join": "Join now",
  "client.cart_modal.title": "Cart",
  "client.cart_modal.item.pre-order": "(pre-order)",
  "client.cart_modal.clear_all": "clear all",
  "client.cart_modal.item_purchased_description": "You have already purchased this item",
  "client.cart_modal.subtotal_title": "Total",
  "client.cart_modal.subtotal_subtitle": "Additional taxes and fees may apply",
  "client.cart_modal.continue_button": "continue shopping",
  "client.cart_modal.checkout_button": "go to checkout",
  "client.cart_modal.footer-auth": "<button class='xl_auth' data-xl-route='login'>Log in</button> or <button class='xl_auth' data-xl-route='signup'>sign up</button> to save items in the cart for later or see previously saved items",
  "client.cart_modal.empty_cart_description": "Your cart is empty. Go to the store and find something awesome to buy.",
  "client.cart_modal.empty_cart_open_store_button": "open store",
  "client.cart_modal.email_label": "Send game activation key to:",
  "client.cart_modal.email_description": "You will receive a key that you need to activate in order to start playing.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonus",
  "client.cart_modal.promo_codes.apply": "Apply",
  "client.cart_modal.promo_codes.remove": "Remove",
  "client.cart_modal.promo_codes.placeholder": "Promo code",
  "client.cart_modal.promo_codes.error_incorrect": "Incorrect promo code.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Code redemption limit has been reached.",
  "client.cart_modal.promo_codes.error_expired": "This promo code has expired.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Couldn't apply the promo code. Please try again later.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "The promo code isn't valid for the items in your cart.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Select a game platform to continue.",
  "client.cart_modal.promo_codes.select_drm": "Select platform",
  "client.cart_modal.promo_codes.tooltip_drm": "To change the platform, remove the promo code and then apply it again",
  "client.bundle_modal.group_items": "Items in bundle",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronze</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Digital content</h3><p>✓ Arts</p><p>✓ Wallpapers</p><p><span style=\"color:rgb(127, 96, 0);\">× In-game soundtracks </span></p><p><span style=\"color:rgb(127, 96, 0);\">× Behind-the-scenes content</span></p><p><br></p><h3>3 In-game</h3><h3>lootboxes</h3><p>✓ Character skins</p><p><span style=\"color:rgb(127, 96, 0);\">× Visual effects </span></p><p><span style=\"color:rgb(127, 96, 0);\">× Storyline cards</span></p><p><br></p><h3>In-game perks</h3><p>✓ x1 Extra pass to the Hall of Chaos</p><p>✓ x1 character rebirth</p><p><br></p><h3>Discord</h3><p>✓ Access to the gamers' channel</p><p><span style=\"color:rgb(127, 96, 0);\">× Access to the developers' channel</span></p><p><br></p><h3>Forum</h3><p>✓ Exclusive nickname color</p><p><span style=\"color:rgb(127, 96, 0);\">× Exclusive emoji</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Animated profile picture </span></p><p>✓ x2 extra points to rank the post (daily)</p><p><br></p><h3>Merch</h3><p>✓ Pins &amp; stickers (every 6 months)</p><p>✓ High-quality poster (every 6 months)</p><p><span style=\"color:rgb(127, 96, 0);\">× Branded plush toys (every year)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× High-quality figurines (every year)</span></p><p><br></p><h3><span style=\"color:rgb(127, 96, 0);\">Offline events</span></h3><p><span style=\"color:rgb(127, 96, 0);\">× Free access to our gaming conventions</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Visit to the company office (every year)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ gives access to Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Silver</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Digital content</h3><p>✓ Arts</p><p>✓ Wallpapers</p><p><span style=\"color:rgb(127, 96, 0);\">× In-game soundtracks</span></p><p>✓ Behind-the-scenes content</p><p><br></p><h3>6 In-game</h3><h3>lootboxes</h3><p>✓ Character skins</p><p>✓ Visual effects</p><p><span style=\"color:rgb(127, 96, 0);\">× Storyline cards</span></p><p><br></p><h3>In-game perks</h3><p>✓ x2 Extra pass to the Hall of Chaos</p><p>✓ x2 character rebirth</p><h3><br></h3><h3>Discord</h3><p>✓ Access to the gamers' channel</p><p><span style=\"color:rgb(127, 96, 0);\">× Access to the developers' channel</span></p><p><br></p><h3>Forum</h3><p>✓ Exclusive nickname color</p><p><span style=\"color:rgb(127, 96, 0);\">× Exclusive emoji</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Animated profile picture</span></p><p>✓ 5x extra points to rank the post (daily)</p><p><br></p><h3>Merch</h3><p>✓ Pins &amp; stickers (every 6 months)</p><p>✓ High-quality poster (every 6 months)</p><p><span style=\"color:rgb(127, 96, 0);\">× Branded plush toys (every year)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× High-quality figurines (every year)</span></p><p><br></p><h3>Offline events</h3><p>✓ Free access to our gaming conventions</p><p><span style=\"color:rgb(127, 96, 0);\">× Visit to the company office (every year)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ gives access to Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Gold</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Digital content</h3><p>✓ Arts</p><p>✓ Wallpapers</p><p>✓ In-game soundtracks</p><p>✓ Behind-the-scenes content</p><p><br></p><h3>9 In-game</h3><h3>lootboxes</h3><p>✓ Character skins</p><p>✓ Visual effects</p><p>✓ Storyline cards</p><p><br></p><h3>In-game perks</h3><p>✓ x5 Extra pass to the Hall of Chaos</p><p>✓ x10 character rebirth</p><p><br></p><h3>Discord</h3><p>✓ Access to the gamers' channel</p><p>✓ Access to the developers' channel</p><p><br></p><h3>Forum</h3><p>✓ Exclusive nickname color</p><p>✓ Exclusive emoji</p><p>✓ Animated profile picture</p><p>✓ x12 extra points to rank the post (daily)</p><p><br></p><h3>Merch</h3><p>✓ Pins &amp; stickers (every 6 months)</p><p>✓ High-quality poster (every 6 months)</p><p>✓ Branded plush toys (every year)</p><p>✓ High-quality figurines (every year)</p><p><br></p><h3>Offline events</h3><p>✓ Free access to our gaming conventions</p><p>✓ Visit to the company office (every year)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ gives access to Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Digital content</h3><p>Arts</p><p>Wallpapers</p><p><span>In-game soundtracks </span></p><p><span>Behind-the-scenes content</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>In-game</h3><h3>lootboxes</h3><p>Character skins</p><p><span>Visual effects </span></p><p><span>Storyline cards</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>In-game perks</h3><p>x1 Extra pass to the Hall of Chaos</p><p>x1 character rebirth</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Access to the gamers' channel</p><p><span>× Access to the developers' channel</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Forum</h3><p>Exclusive nickname color</p><p><span>× Exclusive emoji</span></p><p><span>× Animated profile picture </span></p><p>x2 extra points to rank the post (daily)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Merch</h3><p>Pins &amp; stickers (every 6 months)</p><p>High-quality poster (every 6 months)</p><p><span>Branded plush toys (every year)</span></p><p><span>High-quality figurines (every year)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Offline events</h3><p>✓ Free access to our gaming conventions</p><p>✓ Visit to the company office (every year)</p>",
  "client.buy_button.text": "Buy now",
  "client.buy_button.unavailable": "Unavailable",
  "client.buy_button.pre-order": "Pre-order",
  "client.buy_button.checkout": "Go to checkout",
  "client.store.cart.add_button": "Add to cart",
  "client.store.cart.header_button": "Cart",
  "client.store.no_items": "The store is empty for now. Come back later!",
  "client.store.error.gt10_gk": "For security purposes, you can only purchase up to 10 keys at a time.",
  "client.store.error.no_keys": "The game is temporarily out of stock and will be restocked soon. Feel free to contact us for updates.",
  "client.store.item.purchased": "Purchased",
  "client.store.item.price_from": "from {price}",
  "client.store.item.show_more": "Show details",
  "client.store.successful_purchase": "Success. Thanks for your purchase!",
  "client.store.not_enough_vc": "Not enough virtual currency.</br>Please top it up to complete the purchase.",
  "client.store.non_consumable_item": "You have already purchased this item before, and it's no longer available.",
  "client.store.user_id_input_before_submit_hint": "The items will be credited to this account.",
  "client.store.user_id_input_after_submit_hint": "You are purchasing for:",
  "client.common_error": "Something went wrong. Please try again later or contact our integration team at <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Your balance",
  "client.header.logout": "Log out",
  "client.retailers.edition.title": "Select your copy",
  "client.retailers.country.title": "Select your country",
  "client.retailers.platform.title": "Select the platform",
  "client.retailers.retailer.title": "Select the seller",
  "client.retailers.edition.digital": "Digital",
  "client.retailers.edition.physical": "Physical",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Buy from developer",
  "client.retailers.expand_button.collapse": "Collapse",
  "client.retailers.buy_button.buy": "Buy now",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Most popular",
  "blocks.retailers.components.retailers.standart.value.subtext": "Available now",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Standard Edition</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Game key, exclusive badge, soundtrack, map and additional armor pack",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>YOUR GAME TITLE</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Your Game takes players on a grand adventure filled with detractors, monsters, gold hunters and grave danger.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Available for",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Enter your email",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Subscribe to our newsletter to get the latest news and updates",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Set up button",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Buy now",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Set up button",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Set up button",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Set up button",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Unavailable",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Set up button",
  "client.topup.input.errorMessage": "Something went wrong. Make sure your user ID is correct or try again later.",
  "client.topup.input.successMessage": "You’ve logged in with the {userID} ID. Now you can make purchases on the site.",
  "client.topup.input.username": "Username: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "Level: {level}",
  "client.topup.input.user_not_found": "We couldn't find a user with the ID you specified.<br/>Please try again.",
  "client.topup.input.serverError": "Something went wrong.<br>If you receive this message more than once, please {contact_us}.",
  "client.topup.input.serverError.contact_us": "contact us",
  "client.topup.authorized_title": "You are purchasing for:",
  "blocks.store.values.custom_title": "<h2>{gameTitle} store</h2>"
};

module.exports = localizations;
