/** @prettier */
import { initPartnersRequests } from '../../experiments/partnersRequests/utils';
import { subscriptionsService } from '../actions/subscriptions';
import { initLazy } from './lazyLoad';
import relativeLinks from './relativeLinks';
import { saveSearchParams } from './urlSearchParams';
import { checkYoutubeThumbnail } from './youtubeThumbnail';

export default async (data) => {
  const needRedirect = await saveSearchParams(data);
  checkYoutubeThumbnail(data);
  relativeLinks(data);
  await subscriptionsService.init(data);
  initPartnersRequests(data);
  initLazy();
  return needRedirect;
};
