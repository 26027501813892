import { RenderMode } from '@site-builder/common/src/types/build';
import cn from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';

import type { StepOfUserInstruction } from '@site-builder/common/src/types/values';
import type { FiveSymbolLocale } from '@site-builder/common/src/utils/locale';
import type { IntlShape } from 'react-intl';

import deleteIconSvg from '../../../assets/images/icons/delete-icon.svg';
import { XsollaMetrikaEventNames } from '../../../scripts/afterBlocks/analytics/analyticsEventsList';
import TooltipComponent from '../../xsolla-uikit/DeleteTooltip';
import { EditorText } from '../EditorText';
import MediaWithLink from '../Media/MediaWithLink';

import './style.pcss';

interface InstructionCardProps {
  step: StepOfUserInstruction;
  locale: FiveSymbolLocale;
  onCheckoutBlock: () => void;
  saveInstructionStepText: () => void;
  onMediaButtonClick: () => void;
  renderMode: RenderMode;
  intl: IntlShape;
  deleteStep: () => void;
  canDelete: boolean;
}

const InstructionCard = ({
  renderMode,
  step,
  locale,
  onCheckoutBlock,
  saveInstructionStepText,
  onMediaButtonClick,
  intl,
  deleteStep,
  canDelete,
}: InstructionCardProps) => (
  <section
    className={cn('ui-site-instruction-card', {
      'ui-site-instruction-card--preview': renderMode !== RenderMode.EDITING,
    })}
  >
    <div className='ui-site-instruction-card__text'>
      <EditorText
        size='medium'
        text={step.description[locale]}
        locale={locale}
        values={step}
        onBlur={saveInstructionStepText}
        field='description'
        isServerSideRender={renderMode !== RenderMode.EDITING}
        onCheckoutBlock={onCheckoutBlock}
      />
    </div>
    <div className='ui-site-instruction-card__image'>
      <MediaWithLink
        asset={step.image}
        onMediaButtonClick={onMediaButtonClick}
        renderMode={renderMode}
        disableLazyLoad
        mediaOptions={{
          href: step.image.img,
          'data-fancybox': 'images',
          'data-thumb': step.image.img,
        }}
        xaAttributes={{
          name: XsollaMetrikaEventNames.OPEN_USER_ID_INSTRUCTION_IMAGE,
        }}
      />
      {renderMode === RenderMode.EDITING && canDelete && (
        <button
          className='ui-site-instruction-card__delete-btn ui-site-instruction-card__delete-btn--enable'
          onMouseOut={() => undefined}
          onMouseOver={() => undefined}
          onBlur={() => undefined}
          onFocus={() => undefined}
          onClick={deleteStep}
        >
          <TooltipComponent
            cssClasses={['ui-site-instruction-card__delete-icon']}
            label={intl.messages['editor.topup.deleteStepButton.tooltip']}
          />
        </button>
      )}
      {renderMode === RenderMode.EDITING && !canDelete && (
        <div
          className='material-icons ui-site-instruction-card__delete-btn ui-site-instruction-card__delete-btn--disable'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: deleteIconSvg }}
        />
      )}
    </div>
  </section>
);

export default injectIntl(InstructionCard);
