import { RenderMode } from '@site-builder/common/src/types/build';
import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import {
  Authorization,
  ChangeablePageSettings,
} from '@site-builder/common/src/types/model/landing';
import { FC } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { setAssets } from '../../../reducers/modules/site-builder/assets';
import {
  selectLandingLanguages,
  selectMerchantId,
} from '../../../reducers/modules/site-builder/landing';
import { changePageSettings } from '../../../reducers/modules/site-builder/pages';
import { closeLoginSettings } from '../../../reducers/modules/site-builder/spaces';
import { RootState } from '../../../reducers/types/root-state';
import { analyticsManager } from '../../../scripts/afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../../scripts/afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../../scripts/afterBlocks/analytics/analyticsCategories';
import * as modalWindow from '../../../scripts/ui-components/editor/modalWindow';
import { IModalWindow } from '../../../scripts/ui-components/editor/modalWindow';
import { mergePropsByRenderMode } from '../../../utils/blocks-helper';
import { sendClick, XAEvents } from '../../../utils/init-xsolla-analytics';
import { userIdModalDataid } from '../EditorModals';
import { createStep } from './create-step';
import { UserIdModalView, UserIdModalProps } from './UserIdModal';

enum Status {
  DEFAULT = 'default',
  PROCESSING = 'processing',
  INVALID = 'invalid',
  VALID = 'valid',
}

interface StateProps {
  auth: Authorization;
  locale: FiveSymbolLocale;
  languages: FiveSymbolLocale[];
  renderMode: RenderMode;
  showModal: boolean;
  merchantId: string;
}
const mapStateToProps = ({ pages, landing, modals }: RootState) => ({
  auth: pages.pages[pages.currentPage].auth,
  locale: landing.locale,
  languages: selectLandingLanguages(landing),
  renderMode: landing.renderMode,
  showModal: modals.isShowUserIdModal,
  merchantId: selectMerchantId(landing),
});

const mapDispatchToProps = {
  changePageSettings,
  setAssets,
  closeLoginSettings,
};

type UserIdModalPropsWithoutIntl = Omit<UserIdModalProps, 'intl'>;

interface OwnProps
  extends Pick<
    UserIdModalPropsWithoutIntl,
    'auth' | 'locale' | 'renderMode' | 'hiddenSocials'
  > {
  showModal?: boolean;
  createStep: () => undefined;
  onSubmit: (userId: string) => Promise<boolean>;
  languages: FiveSymbolLocale[];
  merchantId: string;
  onClose?: () => void;
  initValue?: string;
}

export const editorMergeProps = (
  stateProps: StateProps,
  dispatchProps,
  ownProps: OwnProps
): UserIdModalPropsWithoutIntl => {
  const { locale, languages, merchantId, auth } = stateProps;
  const { changePageSettings, setAssets, closeLoginSettings } = dispatchProps;

  const handleSave = (updatedAuth: Authorization, needSave = true) => {
    changePageSettings({
      propName: ChangeablePageSettings.AUTH,
      propValue: updatedAuth,
      needSave,
      needSetData: false,
    });
  };

  const handleSaveInstructionText = (value: string) => {
    const updatedAuth = {
      ...auth,
      instruction: {
        ...auth.instruction,
        instructionCutTextTag: {
          ...auth.instruction.instructionCutTextTag,
          [locale]: value,
        },
      },
    };
    handleSave(updatedAuth);
  };

  const handleAddStep = () => {
    const newStep = createStep({ languages });
    const updatedAuth = {
      ...auth,
      instruction: {
        ...auth.instruction,
        steps: [...auth.instruction.steps, newStep],
      },
    };
    handleSave(updatedAuth);
    sendClick(merchantId, XAEvents.loginSettingsInstructionAddStep);
  };

  const handleDeleteStep = (stepIndex: number) => () => {
    const updatedAuth = {
      ...auth,
      instruction: {
        ...auth.instruction,
        steps: auth.instruction.steps.filter((_, index) => index !== stepIndex),
      },
    };
    handleSave(updatedAuth);
    sendClick(merchantId, XAEvents.loginSettingsInstructionDeleteStep);
  };

  const setupAssetForMedia = (fieldName: 'logo' | 'background') => () => {
    setAssets({
      assetFunction: (updatedAsset, needSave) => {
        const updatedAuth = { ...auth, [fieldName]: updatedAsset };
        handleSave(updatedAuth, needSave);
      },
      currentAsset: auth[`${fieldName}`],
    });
  };

  const setupAssetForInstructionCard = (stepIndex: number) => () => {
    setAssets({
      assetFunction: (updatedAsset, needSave) => {
        const updatedAuth = {
          ...auth,
          instruction: {
            ...auth.instruction,
            steps: auth.instruction.steps.map((step, index) =>
              index === stepIndex ? { ...step, image: updatedAsset } : step
            ),
          },
        };
        handleSave(updatedAuth, needSave);
      },
      currentAsset: auth.instruction.steps[Number(stepIndex)].image,
    });
  };

  return {
    handleSave,
    handleSaveInstructionText,
    handleAddStep,
    handleDeleteStep,
    setupAssetForMedia,
    setupAssetForInstructionCard,
    handleSubmitForm: () => undefined,
    closeModal: closeLoginSettings,
    ...stateProps,
    ...ownProps,
  };
};

export const landingMergeProps = (
  stateProps: StateProps,
  _: any,
  ownProps: OwnProps
): UserIdModalPropsWithoutIntl => {
  const { onSubmit, onClose } = ownProps;

  const close = () => {
    const mountNode = document.querySelector(
      `[data-id=${userIdModalDataid}]`
    ) as IModalWindow;
    modalWindow.closeModalWindow(mountNode);
    onClose?.();

    analyticsManager.sendEvent({
      category: AnalyticsCategories.UID_WIDGET,
      event: AnalyticsActions.CLOSE_MODAL_USER_ID,
      page: `landing_uid-widget_${AnalyticsActions.CLOSE}`,
    });
  };

  const handleSubmitForm = (inputValue, setStatus) => {
    analyticsManager.sendEvent({
      category: AnalyticsCategories.UID_WIDGET,
      event: AnalyticsActions.CONFIRM,
      page: `landing_uid-widget_input-userid-${AnalyticsActions.CONFIRM}`,
    });
    if (inputValue === '') {
      setStatus(Status.DEFAULT);
      return;
    }
    setStatus(Status.PROCESSING);
    onSubmit(inputValue).then((isAuth) => {
      if (!isAuth) {
        setStatus(Status.INVALID);
      }
    });
  };

  return {
    handleSave: () => undefined,
    handleSaveInstructionText: () => undefined,
    handleAddStep: () => undefined,
    handleDeleteStep: () => () => undefined,
    setupAssetForMedia: () => () => undefined,
    setupAssetForInstructionCard: () => () => undefined,
    ...stateProps,
    ...ownProps,
    handleSubmitForm,
    showModal: true,
    closeModal: close,
  };
};

const UserIdModalViewWithIntl = injectIntl(
  UserIdModalView
) as FC<UserIdModalPropsWithoutIntl>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergePropsByRenderMode({ editorMergeProps, landingMergeProps })
)(UserIdModalViewWithIntl);
